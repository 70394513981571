.navbar {
  background-image: linear-gradient(45deg, #071654ed, #031148eb);
  border-bottom: 1px solid #112b92;
}

.hero_banner_img {
  height: 450px;
  object-fit: contain;
  position: relative;
  animation-name: hero_banner_img;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes hero_banner_img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 991px) {
  .hero_banner_img {
    height: 300px;
    margin: auto;
  }
}

.feature-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 0.75rem;
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
.text-shadow-2 {
  text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

.text-justify {
  text-align: justify !important;
}

.rts_section {
  padding: 40px 20px;
}

.rts_icon_span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 4px solid blue;
  border-radius: 50%;
  color: blue;
  font-size: 25px;
}

.bg-dark {
  background-color: #191919 !important;
}

.about-us {
  padding: 40px 20px;
  color: white;
}

.bg-dark-primary {
  background-color: #071654;
}

.about_us_icon_span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid rgb(230, 230, 245);
  font-size: 30px;
  margin-bottom: 20px;
}
